import {Inject, Injectable} from '@angular/core';
import {NAVIGATOR} from '@ideals/types';
import {DeviceDetectorService, DeviceInfo} from 'ngx-device-detector';

export enum OS {
  iOS = 'iOS',
  ANDROID = 'Android'
}

export enum DEVICE {
  iPAD = 'iPad',
  ANDROID = 'Android'
}

interface ICustomWindow extends Window {
  readonly flutter_inappwebview: null;
  readonly standalone: boolean;
}

interface ICustomNavigator extends Navigator {
  readonly standalone: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class BrowserService {
  private readonly _deviceInfo: DeviceInfo;

  constructor(
    private readonly _deviceService: DeviceDetectorService,
    @Inject(NAVIGATOR) private readonly _navigator: Window['navigator']
  ) {
    this._deviceInfo = this._deviceService.getDeviceInfo();
  }

  public isBrowserVersionSupported(version: {
    chrome?: number;
    safari?: number;
    'ms-edge-chromium'?: number;
    'ms-edge'?: number;
    firefox?: number;
    opera?: number;
  }): boolean {
    const supportVersion = version[this._deviceService.browser.toLowerCase()];
    const currentBrowserVersion = Number(this._deviceService.browser_version.split('.')[0]);

    return supportVersion
      ? supportVersion !== 0 && currentBrowserVersion >= supportVersion
      : false;
  }

  public get browser(): string {
    return this._deviceService.browser;
  }

  public get os(): string {
    return this._deviceService.os;
  }

  // iPad Pro/Air detection
  // https://stackoverflow.com/questions/57776001/how-to-detect-ipad-pro-as-ipad-using-javascript/58979271#58979271
  public get isIPadOS(): boolean {
    const MAX_TOUCH_POINTS = 2;

    return this._navigator.maxTouchPoints
      && this._navigator.maxTouchPoints > MAX_TOUCH_POINTS
      && /MacIntel/.test(this._navigator.platform);
  }

  public get isIOS(): boolean {
    return this._deviceService.os === OS.iOS
      || this._deviceInfo.device === DEVICE.iPAD
      || this.isIPadOS;
  }

  public get isAndroid(): boolean {
    return this._deviceService.os === OS.ANDROID || this._deviceInfo.device === DEVICE.ANDROID;
  }

  public get isIE(): boolean {
    const ua = this._navigator.userAgent;
    const msie = ua.indexOf('MSIE ');
    const trident = ua.indexOf('Trident/');

    return (msie > 0 || trident > 0);
  }

  public get isMobile(): boolean {
    return this._deviceService.isMobile()
      || this.isStandalone;
  }

  public get isStandalone(): boolean {
    return document.referrer.startsWith('android-app://')
      || (window.navigator as ICustomNavigator).standalone
      || window.matchMedia('(display-mode: standalone)').matches
      || (window as unknown as ICustomWindow).standalone
      || (window as unknown as ICustomWindow).flutter_inappwebview != null;
  }
}
