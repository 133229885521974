import {IProfileInfo} from '../interfaces/profile-info.interface';

export class ProfileInfo implements IProfileInfo {
  public readonly firstName: string;
  public readonly lastName: string;
  public readonly email: string;
  public readonly title: string;
  public readonly company: string;
  public readonly agreement: boolean;
  public readonly culture: string;
  public readonly state: number;

  constructor(data: IProfileInfo) {
    this.firstName = data.firstName;
    this.lastName = data.lastName;
    this.email = data.email;
    this.title = data.title;
    this.company = data.company;
    this.agreement = data.agreement;
    this.culture = data.culture;
    this.state = data.state;
  }
}
