import {createFeatureSelector, createSelector} from '@ngrx/store';
import {IAppState} from '../reducers/app.reducers';

export const selectAppState = createFeatureSelector<IAppState>('app');

export const selectProfileInfo = createSelector(
  selectAppState,
  (state) => state.profileInfo,
);

export const selectTimeZone = createSelector(
  selectAppState,
  (state) => state.timeZone,
);

export const selectPasswordComplexity = createSelector(
  selectAppState,
  (state) => state.passwordRules,
);

export const selectPhoneNumber = createSelector(
  selectAppState,
  (state) => state.phone,
);

export const selectEmail = createSelector(
  selectAppState,
  (state) => state.email,
);

export const selectUser2fa = createSelector(
  selectAppState,
  (state) => state.require2fa,
);

export const select2faMethods = createSelector(
  selectAppState,
  (state) => state.twoFaMethods,
);
