import {DOCUMENT} from '@angular/common';
import {Inject, Injectable} from '@angular/core';
import {LOCATION, WINDOW} from '@ideals/types';

export const HEAP_ID = '989451727';
export const HEAP_TRACK_EVENTS = [
  'addEventProperties',
  'addUserProperties',
  'clearEventProperties',
  'identify',
  'resetIdentity',
  'removeEventProperty',
  'setEventProperties',
  'track',
  'unsetEventProperty'
];

@Injectable({
  providedIn: 'root'
})
export class HeapService {
  private _heapLoaded = false;
  private _isLoadHeap = this._location.href.includes('stagevdr') || this._location.href.includes('localhost');

  constructor(
    @Inject(DOCUMENT) private readonly _document: Document,
    @Inject(WINDOW) private readonly _window: Window,
    @Inject(LOCATION) private readonly _location: Location
  ) {
  }

  public runHeap(): void {
    if (!this._heapLoaded && !this._isLoadHeap) {
      this._heapLoaded = true;

      (this._window as any).heap = (this._window as any).heap || [];
      const heap = (this._window as any).heap;
      heap.load = this._loadHeap.bind(this);

      heap.load(HEAP_ID);
    }
  }

  private _loadHeap(id: string, config: Object = {}): void {
    const heap = (this._window as any).heap;

    heap.appid = id;
    heap.config = config;

    const script = this._document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.src = `https://cdn.heapanalytics.com/js/heap-${HEAP_ID}.js`;
    this._document.body.appendChild(script);

    HEAP_TRACK_EVENTS.forEach((item) => {
      heap[item] = (...args): void => heap.push([item].concat(Array.prototype.slice.call(args, 0)));
    });
  }
}
